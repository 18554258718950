#for-whom {
}

  
#for-whom .short {
/*   border-bottom: 1px solid var(--second); */
  font-weight: 600;
  margin-left: 10px;

  background-color: var(--block3);
  padding: 10px 30px;
  border-radius: 10px;
  margin: 0;
}

#for-whom .full {
  transition: all 1s ease;
  max-height: 0;
  overflow: hidden;
  padding: 10px 30px;
}

#for-whom .full p {
  font-size: 1rem;
}

#for-whom .full.active {
  max-height: 220px;
}
@media(max-width: 1100px) {
  #for-whom .full.active {
    max-height: 400px;
  }
}


#for-whom .full::before {
  content: '';
  display: block;
  border-top: 1px solid var(--second);
  width: 0%;
  position: relative;
  top: -10px;
}

#for-whom .full.active::before {
  width: 100%;
  transition: width 8s linear;
}
